import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from 'gatsby';
import { FRONT_DOMAIN } from "../config";

import VideoBg from "../components/videoBg";

import "../scss/main.scss";

const SuccessPage = ({ location }) => {
    return (
        <>
            <Helmet>
                <title>Success | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <VideoBg />

            <section className="site-section">
                <div className="content-section" style={ { '--width': '76rem' } }>
                    <div className="tickets__success-msg">
                        <h2 className="content-section__title">
                        YOU&rsquo;VE SECURED YOUR SPOT.<br />
                        PLEASE CHECK YOUR EMAIL TO COMPLETE USER REGISTRATION AND SHARE TICKETS WITH YOUR GUESTS.</h2>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SuccessPage;
